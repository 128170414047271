<template>
  <div class="multi-statistics padding-20 box-shadow-light-grey">
    <div class="filter-box">
      <div class="row">
        <!-- <exclude-query :params="params"></exclude-query> -->
        <el-date-picker
          v-model="params.selectTime"
          style="width: 330px"
          type="monthrange"
          align="right"
          unlink-separator="-"
          format="yyyy-MM"
          value-format="yyyy-MM"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="{
            disabledDate(time) {
              return (
                time < +new Date(2019, 9) ||
                time >
                  +new Date(new Date().getFullYear(), new Date().getMonth())
              );
            },
          }"
        >
        </el-date-picker>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        >
        </select-field>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading"
          >重置</el-button
        >
      </div>
    </div>
    <div class="data-card-list">
      <div
        class="statistics-card"
        v-for="(item, index) in totalData"
        :key="index"
      >
        <div class="data-label">{{ item.label }}</div>
        <div class="statistic-num-text">
          <span class="statistic-num">{{ item.value | formatNum }}</span>
        </div>
      </div>
    </div>
    <div class="data-box" style="height: 610px; overflow: auto">
      <!-- 累计统计线形图 -->
      <div
        v-loading="loading"
        id="multiGraph"
        :style="'width:99%;height:300px;z-index:1'"
      ></div>
      <el-table
        header-row-class-name="table-header"
        ref="multiTable"
        class="multi-table"
        :data="dataList"
        style="width: 100%"
        height="600px"
        v-loading="loading"
        empty-text="暂无数据"
        @cell-click="handleCellClick"
      >
        <el-table-column
          v-for="(item, index) in showField"
          :label="item.fieldName"
          :key="index"
          :prop="item.fieldCode"
          :align="filAlign(item)"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div v-for="(item2, index2) in item.children" :key="index2">
                {{ item2.fieldName }} :
                <span :style="'color:' + item.fontColor">{{
                  scope.row[item2.fieldCode]
                }}</span>
              </div>
            </div>
            <span
              v-else
              :style="'color:' + item.fontColor"
              :class="item.fieldCode === 'date' ? 'drawer-text' : ''"
            >
              {{ scope.row[item.fieldCode] | sortField(item) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 公共抽屉 -->
    <CommonDrawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </div>
</template>

<script>
import echarts from "echarts";
import { formatThousandsDots } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import { yesterdayOptions, labelList, platformList } from "@/assets/js/options";
import { getFields, getMultiStatistics } from "@/api/statics";
import { getBookList } from "@/api/account";
import { mapActions } from "vuex";
export default {
  name: "multiStatistics",
  data() {
    return {
      params: {},
      bookList: [],
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      showCacheList: false,
      cachePage: 1,
      cacheDataTotal: 0,
      hasPage: false,
      loadingGraph: false,
      platformList,
      multiChart: null,
      startTime: null,
      endTime: null,
    };
  },
  computed: {
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
    formatNum: function (value) {
      if (!value) return 0;
      return ("" + value).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    },
  },
  mounted() {
    this.initGraph();
  },
  methods: {
    // 表格点击事件
    handleCellClick(row, column) {
      // 进入平台统计
      if (column.label === "日期") {
        this.nextParams = {
          queryDate: row.date,
        };
        this.showTemplate = "month";
        this.showCommonDrawer = true;
      }
    },
    initGraph() {
      this.multiChart = echarts.init(document.getElementById("multiGraph"));
      window.onresize = this.multiChart.resize;
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getData(flag) {
      //   const today = getRecentlyWeek(true)
      this.startTime = "2020-01";
      this.endTime = `${new Date()
        .toLocaleDateString()
        .split("/")
        .slice(0, 2)
        .map((item) => item.padStart(2, "0"))
        .join("-")}`;
      if (!("selectTime" in this.params)) {
        this.$set(this.params, "selectTime", [this.startTime, this.endTime]);
      }
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.hadleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      const tmpform = {
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        showFieldCodes: tmpFields,
      };
      this.loading = true;
      getMultiStatistics(tmpform)
        .then((res) => {
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.list;
          this.totalData = res.showSumFields;
          this.setGraphData(res.graph);
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpform,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setGraphData(graph) {
      const { x, y } = graph;
      x.reverse();
      y.reverse();
      const colorList = ["#da617b", "#97e2c5", "#b758df", "#6c8198"];
      const seriesData = y.map((item, index) => {
        return {
          name: item.name,
          type: "line",
          data: item.data.reverse(),
          smooth: true,
          itemStyle: {
            normal: {
              lineStyle: {
                color: colorList[index],
              },
            },
          },
        };
      });
      this.multiChart.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          color: colorList,
          legend: {
            data: y.map((item, index) => {
              //   item.name;
              return {
                name: item.name,
                textStyle: {
                  fontSize: 12,
                  border: "none",
                },
              };
            }),
            selected: {
              现金消耗: true,
              税前充值: false,
              税后充值: true,
              利润: true,
              账户消耗: false,
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: x,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "金额",
            },
          ],
          series: seriesData,
        },
        true
      );
    },
    hadleFields(item) {
      if (item.length === 0) return null;
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce((a, b) => a.concat(b));
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    handleFieldChange(send, show) {
      this.sendField = send;
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.multiTable.doLayout();
      });
    },
    handleReset() {
      this.params.selectTime = [this.startTime, this.endTime];
      this.page = 1;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "multiStatistics" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  created() {
    this.getBook();
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );

    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段

      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.handleGetFeilds();
  },
  beforeDestroy() {
    if (this.multiChart) {
      echarts.dispose(this.multiChart);
    }
  },
  components: {
    SelectField,
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
};
</script>
<style lang="scss" scoped>
.multi-statistics {
  background: #fff;
}
</style>
